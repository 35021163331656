import React from 'react'
import { Link } from 'gatsby'
import _kebabCase from 'lodash/kebabCase'

import Image from '../components/Image'
import Content from './Content'

class ProjectSection extends React.Component {
  static defaultProps = {
    projects: [],
    limit: 99,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 99
  }

  state = {
    limit: this.props.limit,
    popoverOpen: false,
    popoverContent: false,
    popoverFade: false
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  openPopover = (event, item) => {
    event.preventDefault()
    this.setState({
      popoverOpen: true,
      popoverContent: item
    })
    setTimeout(() => {
      this.setState({ popoverFade: true })
    }, 50)
  }

  popoverClose = () => {
    this.setState({ popoverFade: false })
    setTimeout(() => {
      this.setState({
        popoverOpen: false,
        popoverContent: false
      })
    }, 300)
  }

  render() {
    const { projects, showLoadMore, loadMoreTitle } = this.props
    const { limit, popoverOpen, popoverContent, popoverFade } = this.state
    const visibleProject = projects.slice(0, limit || projects.length)
    
    return (
      <div className="items-expand">
        <div className="flex">
          {visibleProject.map((item, index) => (
            <Link
              className="item flex"
              to={item.slug}
              key={`project-${_kebabCase(item.title)}`}
              onClick={event => {
                this.openPopover(event, item)
              }}
            >
              <p>{item.title}</p>
              <Image
                className="cover"
                resolutions="small"
                src={item.preview}
                alt={_kebabCase(item.title)}
              />
            </Link>
          ))}
        </div>

        {showLoadMore && visibleProject.length < projects.length && (
          <div className="taCenter">
            <div className="button" onClick={this.increaseLimit}>
              {loadMoreTitle}
            </div>
          </div>
        )}

        {popoverOpen && (
          <div className={`Popover${popoverFade ? ' faded' : ''}`}>
            <div className="Popover--Close" onClick={this.popoverClose} />
            <div className="Popover--Content">
              <div className="project-single">
                <section className="full">
                  <div className="thin">
                    <div className="taCenter">
                      <h3>{popoverContent.title}</h3>
                      <Content src={popoverContent.excerpt} />
                    </div>

                    {(popoverContent.youtubeVideoId || popoverContent.video) && (
                      <div className="video">
                        <div
                          className="progressBarContainer preload"
                          style={{
                            display: 'block'
                          }}
                        />
                        <iframe
                          title={popoverContent.title}
                          src={popoverContent.youtubeVideoId?`https://www.youtube.com/embed/${popoverContent.youtubeVideoId}`:`https://player.vimeo.com/video/${popoverContent.video}`}
                          frameBorder="0"
                          allowFullScreen
                        />
                      </div>
                    )}

                    <Link className="link-project" to={popoverContent.slug}>
                      <h5>LINK</h5>
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProjectSection
